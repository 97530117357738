<template>
    <div>
        <van-loading v-if="loading" size="58px" vertical>加载中...</van-loading>
        <template v-else>
            <div v-if="tBodyData && tBodyData.length">
                <div class="scroll">
                    <div class="bg-fff">
                        <div class="t-tr bg-w">
                            <div class="th lastW tc"></div>
                            <div :class="['th','w'+index]" v-for="(thName,index) in tHeadData">
                                {{thName.name}}
                            </div>
                        </div>
                        <div class="t-tr" v-for="(item,tdIdx) in tBodyData">
                            <div class="td lastW flex_c">
                                <van-checkbox @click="checkboxClick(item,tdIdx)" v-model="item.checked"
                                    icon-size="24px"></van-checkbox>
                            </div>
                            <div :class="['td','w'+index]" v-for="(thN,index) in tHeadData">
                                {{ thN.val == 'specifications'? (item[thN.val] == '1' ? '箱':'托'): item[thN.val]}}</div>
                        </div>
                    </div>
                </div>
                <div class="flex_c_m  tc mt30">
                    <button :disabled="tData.current == 1 || tData.pages == 1" class="tbutton_bg ml10 mr10"
                        @click="changePage('home')">首页</button>
                    <button :disabled="tData.current == 1 || tData.pages == 1" class="tbutton_bg ml10 mr10"
                        @click="changePage('prev')">上一页</button>
                    <button :disabled="tData.current == tData.pages" class="tbutton_bg ml10 mr10"
                        @click="changePage('next')">下一页</button>
                    <button :disabled="tData.current == tData.pages" class="tbutton_bg ml10 mr10"
                        @click="changePage('last')">尾页</button>
                    <span class="ml10 mr10">{{tData.current}}/{{tData.pages}}</span>
                </div>
            </div>
            <div v-else class='error tc pt20 pb30'>
                <!-- <image src="../../../../images/common/no_data.png" class="not-data-icon" mode='widthFix' ></image> -->
                <div class="f999">暂无数据</div>
            </div>
        </template>
    </div>
</template>
<script>
    export default {
        name: 'goodTable',
        props: {
            tHead: {
                type: Array,
                default: []
            },
            tBody: {
                type: Object,
                default: {}
            },
            type: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                parmsJson: {},//出入库数据
                tData: {},//table的数据
                tBodyData: [],//table的数据
                tHeadData: [],//表头
                commodity: '',
                chooseList: [],//已选中的商品
                addLoading: false,
                pages: 1,
                loading: true,
                typeNumber: '',//出入数量字段不一样，保留已经填写的数据
            };
        },
        watch: {
            tBody: {
                deep: true,
                handler(newVal, oldVal) {
                    if (newVal) {
                        this.tData = newVal
                        this.tBodyData = newVal.records || []
                        this.goodChoose()
                    }
                    this.loading = false
                }
            }
        },
        mounted() {
            this.tHeadData = this.tHead
            this.typeNumber = this.type == 'in' ? 'enterNumber' : 'outNumber' //出入数量字段不一样   
            let parmsJson = JSON.parse(localStorage.getItem('parmsJson'))
            this.parmsJson = parmsJson
            this.chooseList = parmsJson.commodityJson || []
        },
        methods: {
            //存储数据,父组件点击“加入订单”调用存储选中的货品
            saveFun() {
                if (this.chooseList.length) {
                    let parmsJson = { ...this.parmsJson, commodityJson: this.chooseList }
                    localStorage.setItem('parmsJson', JSON.stringify(parmsJson))
                    this.$router.go(-1);//返回上一层
                } else {
                    this.$toast2.show('至少选择一个货品！', 'error')
                }

            },
            //选择商品
            checkboxClick(item, i) {
                let goodList = this.tBodyData || []
                let chooseArr = this.chooseList || []
                let typeNumber = this.typeNumber//出入数量字段不一样
                let checked = goodList[i].checked
                let type_Num = ''
                if (checked) {//选中逻辑
                    item['checked'] = true
                    item[typeNumber] = ''
                    chooseArr.push(item)
                } else {//取消选择的逻辑
                    chooseArr.splice(chooseArr.findIndex(e => e.id == item.id), 1)
                }
                this.chooseList = chooseArr
                this.$set(this.tBodyData, i, item)
            },
            //选中明细的过滤
            goodChoose() {
                let goodList = this.tBodyData //当前页的货品信息
                let parmsJson = this.parmsJson //已填写好的出入口信息
                let chooseArr = this.chooseList //已选列表
                let typeNumber = this.typeNumber//出入数量字段不一样
                if (goodList && goodList.length && chooseArr && chooseArr.length) {
                    for (let i = 0; i < goodList.length; i++) {
                        for (let j = 0; j < chooseArr.length; j++) {
                            if (chooseArr[j].id == goodList[i].id) {
                                goodList[i][typeNumber] = (chooseArr[j][typeNumber] || ''); //保留已填好的数量值
                                goodList[i].checked = true //勾选状态
                            }
                        }
                    }
                }
                this.tBodyData = goodList
            },
            //商品分页
            changePage(type) {
                let newIndex = Number(this.tData.current || null)
                let pages = this.tData.pages || null
                if (type === 'home' || type === 'prev') {
                    if (newIndex === 1) return
                } else if (type === 'next' || type === 'last') {
                    if (newIndex === pages) return
                }
                if (type === 'home') {
                    newIndex = 1
                } else if (type === 'prev') {
                    newIndex--
                } else if (type === 'next') {
                    newIndex++
                } else if (type === 'last') {
                    newIndex = pages
                }
                this.$emit("getList", false, { current: newIndex })
            },
        }
    };
</script>
<style scoped>
    .scroll {
        overflow-x: auto;
    }

    .table {
        border: 0px solid darkgray;
    }

    .t-tr {
        display: flex;
        width: 300%;
        justify-content: center;
        height: 100px;
        background-color: #fff;
        align-items: center;
        border-bottom: 1px solid #DBDCDB;
    }

    .bg-w {
        height: 65px;
    }

    .th,
    .td {
        flex: 1;
        justify-content: center;
        text-align: center;
        color: #333;
    }

    .th {
        background-color: #E2EFF6;
        color: #666666;
        display: flex;
        height: 65px;
        align-items: center;
    }

    .lastW {
        flex: 0.5
    }

    .w0,
    .w1 {
        flex: 1.1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .w3 {
        flex: 1.1;
    }
</style>