<template>
    <van-form @submit="saveFun">
        <div class="block_base mt30">
            <div class="flex_m lh100 mlr20 b-line">
                <i class="star-red fz26 mr5">*</i>
                <span class="mr30 pr20 f666">商品编号</span>
                <van-field type='text' v-model="newParms.code" :rules="[{ required: true}]" maxlength="20" class="flex1 tl tc" placeholder="请输入商品编号" />
            </div>
            <div class="flex_m lh100 mlr20 b-line">
                <i class="star-red fz26 mr5">*</i>
                <span class="mr30 pr20 f666">商品名称</span>
                <van-field type='text' v-model="newParms.name" :rules="[{ required: true}]" maxlength="20" class="flex1 tl tc" placeholder="请输入商品名称" />
            </div>
            <div class="flex_m lh100 mlr20 b-line">
                <i class="star-red fz26 mr5">*</i>
                <span class="mr30 pr20 f666">商品规格</span>
                <div class="flex1 flex_end">
                    <span @click='specsFun(item)'
                        :class="['tbutton_bg ml20',{'tbutton_bg_cur':newParms.specifications  == item.id}]"
                        v-for="item in specsArr">{{item.name}}</span>
                </div>
            </div>
            <div class="lh100 flex_m mlr20 b-line">
                <i class="star-red fz26 mr5">*</i>
                <span class="mr30 pr20 f666">每托码货数量</span>
                <van-field type='digit' v-model="newParms.number" :rules="[{ required: true}]" class="flex1 tl tc" placeholder="每托码货数量" />
            </div>
            <div class="flex_m lh100 mlr20 b-line">
                <i class="star-red fz26 mr5">*</i>
                <span class="mr30 pr20 f666">生产日期（批次）</span>
                <div class="flex1 flex_end">
                    <van-field @click="showDatePopupFun(1)" v-model="newParms.productionTime" :rules="[{ required: true}]" right-icon="arrow-down" placeholder="请选择生产日期" readonly/>
                </div>
            </div>
            <div class="flex_m lh100 mlr20 b-line">
                <i class="star-red fz26 mr5">*</i>
                <span class="mr30 pr20 f666">失效日期</span>
                <div class="flex1 tr">
                    <van-field @click="showDatePopupFun(2)" v-model="newParms.invalidTime" :rules="[{ required: true}]" right-icon="arrow-down" placeholder="请选择失效日期" readonly/>
                </div>
            </div>
            <div class="flex_lr_m mlr20 b-line">
                <div class="lh100 flex1 flex_lr_m mb10 pr20">
                    <van-field type='digit' v-model="newParms.unitWeight" :rules="[{ required: true}]"  class="flex1 tl" placeholder="单件重量" />
                    <span class="tr w80">kg</span>
                    <em class="r-line ml20"></em>
                </div>
                <div class="lh100 flex1 flex_lr_m mb10 br_h pl20">
                    <van-field type='digit' v-model="newParms.unitVolume" :rules="[{ required: true}]"  class="flex1 tl" placeholder="单件体积" />
                    <span class="tr w80">cm³</span>
                </div>
            </div>
            <div class="flex_lr_m plr20 ">
                <div class="lh100 flex_lr_m flex1 mb10 pr20">
                    <van-field type='digit' v-model="newParms.unitLong" :rules="[{ required: true}]" class="flex1 tl" placeholder="单件长" />
                    <span class="tr w80">cm</span>
                    <em class="r-line ml20"></em>
                </div>
                <div class="lh100 flex_lr_m flex1 mb10 br_h pl10 pr10">
                    <van-field type='digit' v-model="newParms.unitWide" :rules="[{ required: true}]" class="flex1 tl" placeholder="单件宽" />
                    <span class="tr w80">cm</span>
                    <em class="r-line ml20"></em>
                </div>
                <div class="lh100 flex_lr_m flex1 mb10 br_h pl20">
                    <van-field type='digit' v-model="newParms.unitHigh" :rules="[{ required: true}]" class="flex1 tl" placeholder="单件高" />
                    <span class="tr w80">cm</span>
                </div>
            </div>
        </div>
        <div class="tc mt30">
            <van-button @click="colseFun" class="btn_bg_fff mr30">取消</van-button>
            <van-button  native-type="submit" class="btn_primary">保存新增</van-button>
        </div>
        <!--时间选择-->
        <van-popup v-model="showDatePopup" round position="bottom">
            <van-datetime-picker v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate"
                @confirm="confirmFun" @cancel="cancelFun" />
        </van-popup>
    </van-form>
</template>
<script>
    import { specsArr } from '@/assets/js/warehouse.js'
    import moment from 'moment'
    import {addGoods} from '@/assets/js/apilist.js'
    const API = require('/src/assets/js/apilist.js');
    const parms = {
                    code: '',
                    name: '',
                    number: '',
                    unitWeight: '',
                    unitVolume: '',
                    unitLong: '',
                    unitWide: '',
                    unitHigh: '',
                    specifications: 1,
                    productionTime: '', //生成日期
                    invalidTime: '', //失效日期
                    corpId: '1399298689838665729',
                    creator: '1399298580933586945',
                    updator: '1399298580933586945',
                }
    export default {
        name: 'addGoods',
        data() {
            return {
                specsArr,
                specifications: '1', //规格选中
                newParms:JSON.parse(JSON.stringify(parms)), 
                showDatePopup: false,//日期插件
                currentDate: '',
                minDate: new Date(2000, 0, 1),
                maxDate: new Date(2050, 12, 1),
                dateType: '',//1：生成日期，2：失效
            };
        },
        mounted() {
            this.currentDate = new Date()
        },
        methods: {
            //保存
            saveFun(values){
                let self = this
                this.$axios('post',API.addGoods,this.newParms,'params').then(res => {
                    if(res && res.code == '200'){
                        self.$toast2.show('新建成功！','success')
                        this.$emit("getList",true)
                        this.colseFun()
                    }
                })
            },
            //关闭弹框
            colseFun(){
                this.newParms = JSON.parse(JSON.stringify(parms))
                this.specifications = '1'
                this.$emit("addModalFun",false)
            },
            //规格选择
            specsFun(item) {
                this.newParms.specifications = item.id
            },
            //日期插件弹出
            showDatePopupFun(type) {
                this.dateType = type
                this.showDatePopup = true
            },
            //日期选择
            confirmFun(val) {
                if (this.dateType == '1') {
                    this.newParms.productionTime = moment(val).format('YYYYMMDD')
                } else {
                    this.newParms.invalidTime = moment(val).format('YYYYMMDD')
                }
                this.cancelFun()
            },
            cancelFun() {
                this.showDatePopup = false
                this.dateType = ''
            },
        }
    };
</script>
<style scoped>
    .block_base {
        font-size: 28px;
    }

    .lh100 input {
        display: inline-block;
        width: 50%;
        border: none;
    }

    .w80 {
        display: inline-block;
    }

    .r-line {
        display: inline-block;
        width: 1px;
        height: 60%;
        background-color: #dbdcdb;
    }
    .block_base >>> .van-cell{
        padding:0;
    }
    .block_base >>> .van-field__control{
        text-align: right;
    }
    .tl >>> .van-field__control{
        text-align: left;
    }
</style>