<template>
    <div class="tc g-list">
        <div class="wp80">
            <div class="search_box">
                <van-field v-model="newParms.find" placeholder="搜索商品编号/商品名称" clearable="clearable"
                    @clear="getList(true)" />
                <span @click="getList()" class="search_icon">
                    <van-icon name="search" />
                </span>
            </div>
        </div>

        <div class="pl20 pr20">
            <GoodTable ref="child" :tHead="tHeadData" :tBody="tBodyData" @getList="getList"></GoodTable>
        </div>
        <div class="mt30">
            <div v-if="type == 'in'" class="btn_primary mr30" @click="addModalFun(true)">新增商品</div>
            <div class="btn_primary" @click="saveGoodsFun">加入订单</div>
        </div>
        <div class="modal pl30 pr30 b_s" v-if="showAddModal">
            <AddGood :type="type" @addModalFun="addModalFun" @getList="getList"></AddGood>
        </div>
    </div>
</template>
<script>
    import GoodTable from './components/m_goods_table.vue'
    import AddGood from './components/m_add_goods.vue'
    import { goodsTableHead,outGoodsTableHead} from '@/assets/js/warehouse.js'
    const API = require('/src/assets/js/apilist.js');
    const parms = {
        size: 10,
        current: 1,
        find:''
    }
    export default {
        name: 'goodTable',
        components: {
            GoodTable,
            AddGood
        },
        data() {
            return {
                type: '',//in：入库，out：出库
                showAddModal: false, //新增弹框
                newParms: JSON.parse(JSON.stringify(parms)),
                tHeadData:this.$route.params.type == 'in' ? goodsTableHead : goodsTableHead.concat(outGoodsTableHead), //商品的表头
                tBodyData: {},
                addLoading: false,
                clearable: true,//输入框清空按钮

            };
        },
        mounted() {
            this.type = this.$route.params.type
            this.getList()
        },
        methods: {
            //点击加入订单
            saveGoodsFun() {
                this.$refs.child.saveFun()
            },
            //获取列表
            getList(is_Reset = false,obj = {}) {
                let parms = {...JSON.parse(JSON.stringify(this.newParms)),...obj}
                parms[this.type == 'in' ? 'corpId':'depotId'] = this.$route.params.id //出库单传：仓库地址id  入口单传：客户id
                parms[this.type == 'in' ? 'commodity':'commodityName'] = is_Reset ? '' : parms.find //出入库查询字段名称不统一
                delete parms.find            
                if (is_Reset) {//清空
                    parms = {
                        ...parms,
                        size: 10,
                        current: 1,
                    }
                }
                let self = this
                let path = this.type == 'in' ? API.entryGoodList : API.outGoodList
                this.$axios('post', path, parms).then(res => {
                    if (res && res.code == '200') {
                        self.tBodyData = res.data
                    }
                    // this.$toast.clear();
                })
            },
            //点击新增 
            addModalFun(isFlay) {
                this.showAddModal = isFlay
            },
            //查询
            onSearch() {

            }
        }
    };
</script>
<style scoped>
    .g-list {
        position: relative;
    }

    .wp80 {
        display: inline-block;
        margin: 30px auto;
        width: 80%;
    }

    .table-box {
        width: 100%;
        overflow-x: auto;
        box-sizing: border-box;
    }

    .van-search__content--round {
        display: inline-block;
        background-color: #fff;
    }

    .search_box>>>.van-cell {
        padding: 0;
    }
</style>